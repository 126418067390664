import { Heading, TimeUtil } from 'cuenect-web-core'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentContainer,
  FurioosShowroom,
  InnerContainer,
  LineContainer,
  LiveStreamContainer,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig, isPostEvent } from './../config'

const HIDE_CHAT = '2021-10-06T15:00Z'

const getHideChat = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_CHAT)
}

const ProgramPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params
  const { currentWebinar } = eventConfig

  const noFrame =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('noFrame') === 'true'

  const [scroller, setScroller] = React.useState(null)

  const { t } = useTranslation('program')
  const [loaded, setLoaded] = React.useState(false)

  const currentSlug = `agenda-cys-${lang}`

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame
      {...params}
      noFrame={noFrame}
      onScrollerInit={elem => {
        setScroller(elem)
      }}
      pageName="program"
    >
      {loaded && scroller && (
        <>
          <ContentContainer>
            {!getHideChat() && (
              <LiveStreamContainer
                source={eventConfig.sources[lang]}
                slug={currentSlug}
              />
            )}

            <ProgramContainer
              scroller={scroller}
              source={eventConfig.sources[lang]}
              subdivision={'stage'}
              slug={currentSlug}
              postEvent={isPostEvent()}
            />
          </ContentContainer>
        </>
      )}
    </PageFrame>
  )
}

export default ProgramPage
